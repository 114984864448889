<template>
  <div class="border-b-4 border-t border-l border-r border-green-our border-solid max-w-lg mt-8 shadow-md w-full shadow-glowGreen flex flex-col">
        <figure class="w-full block relative">
          <img class="block w-full h-48 object-cover object-center" :src="`${$store.getters.URLSITE}/photos/battles/${getBattle.battleSettings.image}_m.jpg`" :alt="getBattle.battleSettings.name">
          <figcaption class="absolute flex font-bold inset-0 items-center justify-center p-4 py-2 text-3xl text-center text-shadow1px1">{{getBattle.battleSettings.name}}</figcaption>
        </figure>
      <div class="flex items-stretch justify-center relative select-none">
        <!-- <div class="flex-grow mr-1 pl-6 pr-0 py-2 boxTimeRemainGray300">OPENED</div> -->
        <div class="flex-grow mr-2 pl-1 pr-0 py-2 boxTimeRemainGreen boxTimeRemainClipStart font-bold">UPLOAD <span class="animationUnderline">{{ zostavajuciCas }}</span></div>
        <div class="-ml-3 flex-grow leading-6 mr-2 pl-3 pr-0 py-2 boxTimeRemainBlue bg-blue-our boxTimeRemainClipMiddle bg-opacity-30">VOTE</div>
        <div class="-ml-3 flex-grow leading-6 pr-4 py-2 pl-3 boxTimeRemainPink boxTimeRemainClipEnd bg-pink-700 bg-opacity-30">RESULTS</div>
      </div>

      <div class="bg-black bg-opacity-75 flex-grow px-2">
        <div class="bg-black bg-opacity-50 text-orange-our flex justify-between items-center my-3" title="Upload EXTRA photo" v-if="photoPurchasePanel.show">
            <p class="p-2">Use <strong>{{getBattle.generalSettings.photoSlotPrice}} credits</strong> to upload EXTRA photo</p><span class="bg-orange-our text-white p-2 cursor-pointer" @click="submitPurchasePhotoSlot()">UNLOCK</span>
        </div>
        <div class="px-2 py-1 flex justify-start flex-wrap">
          <green-box-photos v-for="fotka in nahraneFotoFree" :key="fotka.nazovSuboru" :photoFileName="fotka.photoFileName" :battleId="battleId"></green-box-photos>
          <green-box-photos-empty v-for="n in freePhotoSlotsLeft" :key="n" :battleId="battleId"></green-box-photos-empty>
        </div>
        <div class="px-2 py-1 flex justify-start flex-wrap mb-5">  
          <green-box-photos v-for="fotka in nahraneFotoCredit" :key="fotka.nazovSuboru" :photoFileName="fotka.photoFileName" :battleId="battleId"></green-box-photos>
          <template v-if="getBattle.paidServices.photoSlots-nahraneFotoCredit.length>0">
            <green-box-photos-icons v-for="(unlocked,idx2) in (getBattle.paidServices.photoSlots-nahraneFotoCredit.length)" :key="idx2" :typeIcon="'unlocked'" :battleId="battleId" :indexIkony="idx2" :pocetZaplatenychSlotov="getBattle.paidServices.photoSlots"></green-box-photos-icons>
          </template>
          <template v-if="getBattle.battleSettings.maxPhotosCredit-getBattle.paidServices.photoSlots>0">
            <green-box-photos-icons v-for="(locked,idx3) in (getBattle.battleSettings.maxPhotosCredit-getBattle.paidServices.photoSlots)" :key="idx3" :typeIcon="'locked'" :battleId="battleId" @openPhotoPurchase="openPhotoPurchasePanel" :indexIkony="idx3" :pocetZaplatenychSlotov="getBattle.paidServices.photoSlots"></green-box-photos-icons>
          </template>
        </div>
        <div v-if="!getUserLogged" class="text-center border-t-2 border-gray-800 border-dashed">
          <figure class="mx-auto my-8 relative w-32 cursor-pointer" v-if="!getUserLogged" @click.prevent="loginPanel()">
              <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/joinContest.svg`" alt="Join Contest">
              <figcaption class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 text-center text-xl top-1/2 transform">JOIN</figcaption>
          </figure>
        </div>
      </div>
      <div class="bg-green-our bg-opacity-50 flex justify-between p-2">
        <span class="text-sm cursor-pointer text-orange-our" @click="showBattleRules()">BATTLE RULES</span>
        <span class="text-sm cursor-pointer" @click="showGeneralRules()">GENERAL RULES</span>
      </div>
    </div>
</template>

<script>
import GreenBoxPhotosEmpty from './GreenBoxPhotosEmpty'
import GreenBoxPhotos from './GreenBoxPhotos.vue';
import functions from '../functions.vue';
import GreenBoxPhotosIcons from './GreenBoxPhotosIcons.vue';
//const TIME_LIMIT = 20;
export default {
  mixins: [functions],
  props:['battleId'],
  components:{GreenBoxPhotosIcons,GreenBoxPhotosEmpty,GreenBoxPhotos},
    beforeCreate(){
    // console.log('green before created',this.getBattle);
  },
  data(){
    return {
      photoPurchasePanel:{
        show:false
      }
    }
  },
  computed:{
    getUserLogged(){
      return this.$store.getters.getUserLogged;
    },
    getBattle(){
      return this.$store.getters.getBattle(this.battleId);
    },
    nahraneFotoFree(){
      return this.getBattle.photosUploaded.filter((element)=>{
        return element.freePhoto==1;
      });
    },
    nahraneFotoCredit(){
      return this.getBattle.photosUploaded.filter((element)=>{
        return element.freePhoto==0;
      });
    },
    freePhotoSlotsLeft(){
      return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosFree)-(parseInt(this.nahraneFotoFree.length)));
    },
    ExtraPhotoSlotsLeft(){
      return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosCredit)-parseInt(this.getBattle.paidServices.photoSlots));
    },
    zostavajuciCas(){
      let cas=this.getTimeRemainingWithDays(this.getBattle.battleSettings.toVoteStart);
      if(cas.days){
        return `${cas.days} LEFT`
      }else if(cas.hours){
        return `${cas.hours} LEFT`
      }
      return ""
    },
  },
  methods:{
    showGeneralRules(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'GeneralRules',data:{}});
    },
    showBattleRules(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'BattleRules',data:{rules:this.getBattle.battleRules,battleImg:this.getBattle.battleSettings.image,battleName:this.getBattle.battleSettings.name}});
    },
    loginPanel(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
    },
    openPhotoPurchasePanel(data){
      if(data===true){
        this.photoPurchasePanel.show=true;
      }
    },
    submitPurchasePhotoSlot(){
      console.log("clicked");
      this.purchasePhotoSlot(this.battleId);
      this.photoPurchasePanel.show=false;
    }
  },
}
</script>
<template>
    <template v-if="getUserLogged">
        <figure v-if="typeIcon=='unlocked'" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-orange-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/unlock.svg'" class="w-full h-full object-cover" @click.prevent="modalUploadPhoto()" alt="Extra photo slot UNLOCKED">
        </figure>
        
        <figure v-if="typeIcon=='locked' && zobrazOranzovy" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-orange-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/lock.svg'" class="w-full h-full object-cover" @click.prevent="openPurchasePhotoSlot()" alt="Extra photo slot LOCKED">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-orange-our">{{getCenaCredits}}</figcaption>
        </figure>

        <figure v-if="typeIcon=='locked' && !zobrazOranzovy" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-gray-300 mx-2 my-1 md:mx-1 flex items-center">
            <img :src="$store.getters.URLPICTURES+'/lockGray.svg'" class="w-full h-full object-cover" alt="Extra photo slot LOCKED">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-gray-300">{{getCenaCredits}}</figcaption>
        </figure>
    </template>
    <template v-else>
        <figure v-if="typeIcon=='unlocked'" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-green-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/unlock.svg'" class="w-full h-full object-cover" @click.prevent="loginPanel()"   alt="Extra photo slot UNLOCKED">
        </figure>
        
        <figure v-if="typeIcon=='locked' && zobrazOranzovy" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-orange-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/lock.svg'" class="w-full h-full object-cover" @click.prevent="loginPanel()"   alt="Extra photo slot LOCKED">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-orange-our">{{getCenaCredits}}</figcaption>
        </figure>

        <figure v-if="typeIcon=='locked' && !zobrazOranzovy" class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-gray-300 mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/lockGray.svg'" class="w-full h-full object-cover" @click.prevent="loginPanel()" alt="Extra photo slot LOCKED">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-gray-300">{{getCenaCredits}}</figcaption>
        </figure>
    </template>
    {{firstLock}}
</template>

<script>
export default {
    props:['battleId','typeIcon','indexIkony','pocetZaplatenychSlotov'],
    emits: ["operPhotoPurchase"],
    created(){
        console.log('zobrazOranzovy',this.typeIcon,this.indexIkony,this.zobrazOranzovy);
    },
    computed:{
        getUserLogged(){
            return this.$store.getters.getUserLogged;
        },
        getCenaCredits(){
            if(this.indexIkony!==false){
                return `${this.getBattle.battleSettings.photoSlotPrice[this.indexIkony+this.pocetZaplatenychSlotov]} CRED.`;
            }
            return "";
        },
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
        zobrazOranzovy(){
            if(this.indexIkony===0){
                return true;
            }else{
                return false;
            }
        }
    },
    methods:{
        modalUploadPhoto(){
            this.$store.commit('setLowerModal',{visible:true,whatToShow:'UploadImage',data:{battleId:this.battleId}}); 
        },
        openPurchasePhotoSlot(){
            this.$emit('operPhotoPurchase',true);
        },
        loginPanel(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
        }
    }

}
</script>
<template>
  <img :src="uploadedPhotoUrl(zobrazFotografiu)" @click="modalZoomInImage(getUploadedPhoto)" @error="zobrazFotografiu=false" class="cursor-pointer w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 mx-2 my-1 md:mx-1 object-contain"  :alt="uploadedPhotoTitle" :class="uploadedPhotoBorder">
</template>

<script>
import functions from '../functions.vue';
export default {
    mixins: [functions],
    data(){
        return{
            zobrazFotografiu:true
        }
    },
    props:['photoFileName','battleId'],
    beforeMount(){
    },
    computed:{
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
        getUploadedPhoto(){
            return this.getBattle.photosUploaded.find((el)=>el.photoFileName==this.photoFileName);
        },
        uploadedPhotoTitle(){
            if(this.getUploadedPhoto.freePhoto==0){
                return "Uploaded paid photo";
            }else{
                return "Uploaded free photo";
            }
        },
        uploadedPhotoBorder(){
            if(this.getUploadedPhoto.freePhoto==0){
                return {'border-orange-our':true}
            }
            return {'border-green-our':true}
        }
    },
    methods:{
        uploadedPhotoUrl(zobrazFotografiu){
            if(zobrazFotografiu==false){
                return this.$store.getters.URLPICTURES+"/noImage.svg";
            }else{
                return this.getUploadedPhoto.photoPath+"_t.jpg";
            }
        },
        modalZoomInImage(photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:photo.exif,
            size:photo.size,
            isAdult:photo.isAdult,
            idPhoto:photo.idPhoto,
            battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
        },
    }
}
</script>
<template>
    <template v-if="getUserLogged">
        <figure class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-green-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/empty.svg'" class="w-full h-full object-cover cursor-pointer" @click.prevent="modalUploadPhoto()">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-green-our">FREE</figcaption>
        </figure>
    </template>
    <template v-else>
        <figure class="relative w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 border-green-our mx-2 my-1 md:mx-1 cursor-pointer flex items-center">
            <img :src="$store.getters.URLPICTURES+'/empty.svg'" class="w-full h-full object-cover cursor-pointer" @click.prevent="loginPanel()">
            <figcaption class="absolute -bottom-0 -left-1 -right-1 text-center text-xs text-green-our">FREE</figcaption>
        </figure>
    </template>

</template>

<script>
export default {
    props:['battleId'],
    computed:{
        getUserLogged(){
            return this.$store.getters.getUserLogged;
        }
    },
    methods:{
        modalUploadPhoto(){
            this.$store.commit('setLowerModal',{visible:true,whatToShow:'UploadImage',data:{battleId:this.battleId}}); 
        },
        loginPanel(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
        }
    }

}
</script>
<template>
  <img :src="uploadedPhotoUrl(zobrazFotografiu)" @click.prevent="modalZoomInImage()" @error="zobrazFotografiu=false" class="w-12 h-12 md:w-16 md:h-16 lg:w-18 lg:h-18 border-b-2 mx-2 my-1 md:mx-1 cursor-pointer object-contain"  :alt="uploadedPhotoTitle"  :class="uploadedPhotoBorder">
</template>

<script>
import functions from '../functions.vue';
export default {
    mixins: [functions],
    data(){
        return{
            zobrazFotografiu:true
        }
    },
    props:['photoFileName','battleId'],
    computed:{
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
        getUploadedPhoto(){
            return this.getBattle.photosUploaded.find((el)=>el.photoFileName==this.photoFileName);
        },
        uploadedPhotoTitle(){
            if(this.getUploadedPhoto.freePhoto==0){
                return "Uploaded paid photo";
            }else{
                return "Uploaded free photo";
            }
        },
        uploadedPhotoBorder(){
            if(this.getUploadedPhoto.freePhoto==0){
                return {'border-orange-our':true}
            }
            return {'border-green-our':true}
        }
    },
    methods:{
        uploadedPhotoUrl(zobrazFotografiu){
            if(zobrazFotografiu==false){
                return this.$store.getters.URLPICTURES+"/noImage.svg";
            }else{
                return this.getUploadedPhoto.photoPath+"_t.jpg";
            }
        },
        modalZoomInImage(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{photoPath:this.getUploadedPhoto.photoPath,
            photoFileName:this.getUploadedPhoto.photoFileName,
            exif:this.getUploadedPhoto.exif,
            size:this.getUploadedPhoto.size,
            isAdult:this.getUploadedPhoto.isAdult,
            idPhoto:this.getUploadedPhoto.idPhoto,
            deletePhoto:{'battleId':this.battleId,'deletePhoto':true},
            battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
        },

        modalUploadPhoto(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'UploadImage',data:{battleId:this.battleId}}); 
        }
    }
}
</script>
<template>
  <div class="border-b-4 border-t border-l border-r border-blue-our border-solid max-w-lg mt-8 shadow-md w-full shadow-glowBlue flex flex-col">
    <figure class="w-full block relative">
      <img class="block w-full h-48 object-cover object-center" :src="`${$store.getters.URLSITE}/photos/battles/${getBattle.battleSettings.image}_m.jpg`" :alt="getBattle.battleSettings.name">
      <figcaption class="absolute flex font-bold inset-0 items-center justify-center p-4 py-2 text-3xl text-center text-shadow1px1">{{ getBattle.battleSettings.name }}</figcaption>
    </figure>

    <div class="flex items-stretch justify-center relative select-none">
      <div class="flex-grow mr-2 pl-1 pr-0 py-2 text-shadow1px bg-green-our boxTimeRemainClipStart bg-opacity-30">UPLOAD</div>
      <div class="-ml-3 flex-grow leading-6 mr-2 pl-3 pr-0 py-2 boxTimeRemainBlue bg-blue-our boxTimeRemainClipMiddle font-bold">VOTE <span class="animationUnderline">{{ zostavajuciCas }}</span></div>
      <div class="-ml-3 flex-grow leading-6 pr-4 py-2 pl-3 boxTimeRemainPink boxTimeRemainClipEnd bg-pink-700 bg-opacity-30">RESULTS</div>
    </div>

    <div class="bg-black bg-opacity-75 flex-grow px-2">
      <div class="bg-black text-orange-our my-3 flex justify-between items-center" title="Upload EXTRA photo" v-if="photoPurchasePanel.show">
          <p class="p-2">Use <strong>{{getBattle.generalSettings.photoSlotPrice}} credits</strong> to upload EXTRA photo</p><span class="bg-orange-our text-white p-2 cursor-pointer" @click="submitPurchasePhotoSlot()">UNLOCK</span>
      </div>
      <div  class="py-1 flex justify-start flex-wrap items-center">
        <blue-box-photos v-for="(fotka,idx) in nahraneFotoFree" :key="idx" :photoFileName="fotka.photoFileName" :battleId="battleId"></blue-box-photos>
        <blue-box-photos-empty v-for="n in freePhotoSlotsLeft" :key="n" :battleId="battleId" @freePhotoPanelSwitch="freePhotoPanelChange()"></blue-box-photos-empty>
        <div v-if="freePhotoPanel" class="text-center text-gray-300 flex-grow text-sm">
          <span class="font-bold">FREE SLOTS</span><br>ONLY IN <span class="text-green-our">UPLOAD WEEK</span>
        </div>
      </div>
      <div  class="py-1 flex justify-start flex-wrap">
        <blue-box-photos v-for="(fotka,idx1) in nahraneFotoCredit" :key="idx1" :photoFileName="fotka.photoFileName" :battleId="battleId"></blue-box-photos>
        <template v-if="getBattle.paidServices.photoSlots-nahraneFotoCredit.length>0">
          <blue-box-photos-icons v-for="(unlock,idx2) in (getBattle.paidServices.photoSlots-nahraneFotoCredit.length)" :key="idx2" :battleId="battleId" :typeIcon="'unlocked'" :indexIkony="idx2" :pocetZaplatenychSlotov="getBattle.paidServices.photoSlots"></blue-box-photos-icons>
        </template>
        <template v-if="getBattle.battleSettings.maxPhotosCredit-getBattle.paidServices.photoSlots>0">
          <blue-box-photos-icons v-for="(lock,idx3) in (getBattle.battleSettings.maxPhotosCredit-getBattle.paidServices.photoSlots)" @operPhotoPurchase="openPhotoPurchasePanel" :key="idx3" :battleId="battleId" :typeIcon="'locked'" :indexIkony="idx3" :pocetZaplatenychSlotov="getBattle.paidServices.photoSlots"></blue-box-photos-icons>
        </template>
      </div>
      
      <div class="border-dashed border-gray-800 border-t-2 box-border flex justify-around mb-2 mt-4 py-4">
        <div class="w-1/2 flex justify-around mr-1">
          <figure @click="clickExtraSelectAction()" class="w-4/12 relative select-none cursor-pointer">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/extraSelect.svg`" alt="Extra selects">
            <figcaption class="absolute top-1/2 sm:font-bold left-0 right-0 text-center text-sm">EXTRA<br>SELECT</figcaption>
          </figure>

          <figure v-if="!zobrazSelected()" @click="clickSelectAction()" class="cursor-pointer relative select-none w-8/12">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/select.svg`" alt="Select">
            <figcaption class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 text-center text-xl top-1/2 transform">SELECT<span class="block font-bold">{{PhotosSelectLeft}}/{{MaxPhotosSelect}}</span></figcaption>
          </figure>
          <figure v-else class="cursor-pointer relative select-none w-8/12">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/select_ok.svg`" alt="Select">
            <figcaption class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 text-center text-xl top-1/2 transform">SELECTED</figcaption>
          </figure>
        </div>
        <div class="w-1/2 flex justify-around ml-1">
          <figure v-if="!zobrazVoted()" @click="clickVoteAction()" class="cursor-pointer relative select-none w-8/12">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/vote.svg`" alt="Vote">
            <figcaption class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 text-center text-xl top-1/2 transform">VOTE<span class="block font-bold">{{VoteLeft}}/{{getBattle.generalSettings.maxVotes}}</span></figcaption>
          </figure>
          <figure v-else class="relative select-none w-8/12">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/voted_ok.svg`" alt="Voted">
            <figcaption class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 text-center text-xl top-1/2 transform">VOTED</figcaption>
          </figure>

          <figure @click="clickResetVoteAction()" class="w-4/12 relative select-none cursor-pointer">
            <img class="block w-full h-auto" :src="`${$store.getters.URLSITE}/pictures/voteReset.svg`" alt="Reset votes">
            <figcaption class="absolute top-1/2 sm:font-bold left-0 right-0 text-center text-sm">RESET<br>VOTES</figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div class="bg-blue-our bg-opacity-50 flex justify-between p-2">
      <span class="text-sm cursor-pointer text-orange-our text-center sm:text-left" @click="showBattleRules()">BATTLE RULES</span>
      <span class="font-bold sm:text-left text-center text-sm text-white">UNSEEN PHOTOS: {{UnseenPhotos}}</span>
      <span class="text-sm cursor-pointer text-center sm:text-left" @click="showGeneralRules()">GENERAL RULES</span>
    </div>
  </div>
</template>

<script>
import BlueBoxPhotosIcons from "./BlueBoxPhotosIcons.vue"
import BlueBoxPhotos from "./BlueBoxPhotos.vue";
import BlueBoxPhotosEmpty from "./BlueBoxPhotosEmpty.vue";
import functions from '../functions.vue';
export default {
  mixins: [functions],
  props: ["battleId"],
  components: { BlueBoxPhotos,BlueBoxPhotosEmpty,BlueBoxPhotosIcons},
  data() {
    return {
      photoPurchasePanel:{
        show:false
      },
      freePhotoPanel:false,
      firstLock:true,
    };
  },
  computed: {
    getBattle(){
      return this.$store.getters.getBattle(this.battleId);
    },

    nahraneFotoFree(){
      return this.getBattle.photosUploaded.filter((element)=>{
        return element.freePhoto==1;
      });
    },
    nahraneFotoCredit(){
      return this.getBattle.photosUploaded.filter((element)=>{
        return element.freePhoto==0;
      });
    },
    freePhotoSlotsLeft(){
      return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosFree)-(parseInt(this.nahraneFotoFree.length)));
    },
    ExtraPhotoSlotsLeft(){
      return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosCredit)-parseInt(this.getBattle.paidServices.photoSlots));
    },
    MaxPhotosSelect(){
      var maxPocetSelectovUzivatela=parseInt(this.getBattle.generalSettings.maxSelect)+(parseInt(this.getBattle.generalSettings.extraSelectPurchase)*parseInt(this.getBattle.paidServices.extraSelect));
      return maxPocetSelectovUzivatela;
    },
    PhotosSelectLeft(){
      return parseInt(this.getBattle.activeBattle.selectLeft);
    },
    VoteLeft(){
      return parseInt(this.getBattle.activeBattle.voteLeft);
    },
    UnseenPhotos(){
      var unseenPhotosBySelect=parseInt(this.getBattle.activeBattle.totalPhotosInBattle)-parseInt(this.getBattle.activeBattle.alreadySeenPhotos);
      if(unseenPhotosBySelect>200){//----------nad 500 zobrazenie %
        return "200+";
      }else if(unseenPhotosBySelect>10){
        return Math.abs(Math.floor(unseenPhotosBySelect/10)*10)+"+";
      }else if(unseenPhotosBySelect>0){
        return unseenPhotosBySelect+"+";
      }else{
        return "NONE";
      }
    },
    allSeenPhotos(){
        return this.getBattle.activeBattle.allSeenPhotos;
    },
    zostavajuciCas(){
      let cas=this.getTimeRemainingWithDays(this.getBattle.battleSettings.toBattleEnd);
      if(cas.days){
        return `${cas.days} LEFT`
      }else if(cas.hours){
        return `${cas.hours} LEFT`
      }
      return ""
    },
    getUserLogged(){
      return this.$store.getters.getUserLogged;
    },
  },
  methods: {
    clickVoteAction(){
      if(this.getUserLogged){
        if(this.VoteLeft>0){
          this.openVoteWindow();
        }
      }else{
        this.loginPanel();
      }
    },
    zobrazVoted(){
      if(this.getUserLogged){
        if(this.VoteLeft===0){
          return true;
        }
      }
      return false;
    },
    zobrazSelected(){
      if(this.getUserLogged){
        if(this.PhotosSelectLeft===0){
          return true;
        }
      }
      return false;
    },
    clickSelectAction(){
      if(this.getUserLogged){
        if(this.PhotosSelectLeft>0){
          this.openSelectWindow();
        }
      }else{
        this.loginPanel();
      }
    },
    clickResetVoteAction(){
      if(this.getUserLogged){
        this.resetVote();
      }else{
        this.loginPanel();
      }
    },
    clickExtraSelectAction(){
      if(this.getUserLogged){
        this.BuyExtraSelectSlot();
      }else{
        this.loginPanel();
      }
    },
    freePhotoPanelChange(){
      this.freePhotoPanel=true;
    },
    loginPanel(){
          this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
    },
    showBattleRules(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'BattleRules',data:{rules:this.getBattle.battleRules,battleImg:this.getBattle.battleSettings.image,battleName:this.getBattle.battleSettings.name}});
    },
    showGeneralRules(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'GeneralRules',data:{}});
    },
    openPhotoPurchasePanel(data){
      if(data===true){
        this.photoPurchasePanel.show=true;
      }
    },
    submitPurchasePhotoSlot(){
      console.log("clicked");
      this.purchasePhotoSlot(this.battleId);
      this.photoPurchasePanel.show=false;
    },
    openSelectWindow(){
        this.$store.commit('setLowerModal',{visible:true,whatToShow:'SelectPhoto',data:{battleId:this.battleId}}); 
    },
    openVoteWindow(){
        this.$store.commit('setLowerModal',{visible:true,whatToShow:'VotePhoto',data:{battleId:this.battleId}}); 
    },
    BuyExtraSelectSlot(){
      //--------vypise okno uzivatel si moze kupit dalsie select sloty
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'BuyExtraSelectSlots',data:{battleId:this.battleId}});
    },
    BuyRewatchPhotosAgain(){
      //--------vypise okno uzivatel si moze kupit dalsie prezretie fotiek
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'BuyRewatchPhotosAgain',data:{'battleId':this.battleId,'close':'bothWindows'}});
    },
    resetVote(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'ResetVotes',data:{battleId:this.battleId}}); 
    },
  },
};
</script>